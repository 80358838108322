import { AxiosError } from 'axios';

import { ApiBase } from 'apis/api';
import { Analytics } from 'apis/Analytics';
import { handleError } from 'utils/error';

const checkoutPageVisit = async (code: string) => {
  try {
    const fbEventId: string =
      (await ApiBase.get(`fb-track/checkout/${code}`))?.data?.data
        ?.facebook_event?.code ?? '';

    Analytics.logEvent({
      event: 'checkoutVisit',
      client_code: code,
      fb_event_id: fbEventId,
    });
  } catch (error) {
    handleError(error as AxiosError);
  }
};

const paymentSuccessful = async (props: {
  code: string;
  orderId: string;
  data: {
    currencyCode: string;
    paymentMethod: string;
    transactionId: string;
    transactionTotal: number;
    transactionAffiliation: string;
    transactionProducts: {
      sku: string;
      name: string;
      category: string;
      price: string;
      quantity: number;
    }[];
  };
}) => {
  try {
    const fbEventId: string =
      (await ApiBase.get(`fb-track/payment/${props.code}/${props.orderId}`))
        ?.data?.data?.facebook_event?.code ?? '';

    Analytics.logEvent({
      event: 'paymentSuccessful',
      fb_event_id: fbEventId,
      ...props.data,
    });
  } catch (error) {
    handleError(error as AxiosError);
  }
};

export const Events = {
  checkoutPageVisit,
  paymentSuccessful,
};
